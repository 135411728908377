/*包含以下四种的链接*/
a {
  text-decoration: none;
  color: #5f616d;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  color: #5f616d;
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
.our_services {
  background-color: #fff;
}
.our_services .box {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 92px 0 rgba(218,218,218,.75);
  color: #999;
  font-size: 16px;
  transform: translateY(-50%);
}
.our_services .cpt {
  color: #363636;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 10px;
}
.our_services .wave {
  background: url(../img/our_services.png) no-repeat 50% 0;
  height: 783px;
  margin-top: -25px;
}
.our_services .wave h3 {
  color: #5f616d;
  line-height: 50px;
}
.our_services .wave h3 small {
  font-size: 16px;
  line-height: 2;
}
.our_services .icon {
  background: url(../img/s.png) no-repeat;
  border-radius: 70px;
  border: 1px solid #46a0da;
  display: inline-block;
  height: 70px;
  margin: 0;
  margin-right: 4px;
  width: 70px;
}
.our_services .icon-2 {
  background-position: -70px 0;
}
.our_services .icon-3 {
  background-position: -140px 0;
}
.our_services .icon-4 {
  background-position: -210px 0;
}

.our_services .wave .icon {
  border: none;
}
.our_services .wave .cpt {
  color: #258ffc;
  font-size: 16px;
  margin-top: 20px;
}
.our_services .wave .desc {
  color: #5f616d;
  font-size: 14px;
  line-height: 2;
}
.our_services .icon-box {
  border-radius: 84px;
  display: inline-block;
  height: 84px;
  width: 84px;
  border: 10px solid #bdddfe;
  background-color: #2360f5;
  background-image: url(../img/mask_84x84.png);
  transition: all .5s;
}
.our_services .icon-box:nth-child(1) {
  animation: blink 5s infinite alternate;
}
.our_services .icon-box:nth-child(2) {
  animation: blink 4.5s infinite alternate;
}
.our_services .icon-box:nth-child(3) {
  animation: blink 4s infinite alternate;
}
.our_services .icon-box:nth-child(4) {
  animation: blink 2.5s infinite alternate;
}
.our_services .icon-box:nth-child(5) {
  animation: blink 2.5s infinite alternate;
}
.our_services .icon-box:hover {
  background-color: #f7800c;
  border: 10px solid #000;
}
.our_services .wave .icons {
  max-width: 1400px;
  margin: 0 auto;
}
.our_services .wave .icon {
  height: 84px;
  width: 84px;
}
.our_services .icon-5 {
  background-position: 0 -70px;
}
.our_services .icon-6 {
  background-position: -84px -70px;
}
.our_services .icon-7 {
  background-position: -168px -70px;
}
.our_services .icon-8 {
  background-position: -252px -70px;
}
.our_services .icon-9 {
  background-position: -336px -70px;
}
.our_services .icon-10 {
  background-position: -420px -70px;
}
.our_services .wave .icons {
  margin-top: 240px;
}
.our_services .wave .icons .col:nth-child(even) {
  margin-top: 100px;
}
@keyframes blink {
  0% { border: 10px solid #bdddfe; }
  100% { border: 10px solid #feddbd; }
}

.our_strengths {
  background-color: #4899eb;
  color: #fff;
  padding-top: 85px;
  padding-bottom: 140px;
}
.our_strengths h3 {
  color: #fff;
  line-height: 50px;
}
.our_strengths h3 small {
  font-size: 16px;
  line-height: 2;
}
.our_strengths .box {
  border: 5px solid #fff;
  border-radius: 15px;
  height: 340px;
  font-size: 16px;
  line-height: 24px;
  transition: all .25s;
}
.our_strengths .box:hover {
  transform: scale(1.02);
  box-shadow: 0 0 50px 0 #779;
}
.our_strengths .cpt {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
}
.our_strengths .col .pic {
  height: 100px;
  margin: 25px auto;
  width: 100px;
}
.our_strengths .col-1 .pic {
  background: url(../img/stn.png) no-repeat 0 0;
}
.our_strengths .col-2 .pic  {
  background: url(../img/stn.png) no-repeat -100px 0;
}
.our_strengths .col-3 .pic  {
  background: url(../img/stn.png) no-repeat -200px 0;
}

.corporate_culture {
  background-color: #f2f6f9;
}
.corporate_culture h3 {
  line-height: 50px;
}
.corporate_culture h3 small {
  line-height: 2;
  font-size: 16px;
}
.corporate_culture .counter {
  background-color: #fff;
  height: 260px;
  transform: translateY(-50%);
}
.corporate_culture .counter .cpt {
  color: #258ffc;
  font-size: 24px;
  margin-bottom: 15px;
}
.corporate_culture .box .cpt {
  color: #5f616d;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 15px;
}
.corporate_culture .box .num {
  color: #258ffc;
  line-height: 1.25;
  font-size: 50px;
}
.corporate_culture hr {
  border-top: 1px solid #555;
  margin: 15px auto;
  width: 70px;
}
.corporate_culture .main {
  padding-bottom: 170px;
}
.corporate_culture .main .desc {
  color: #44484a;
  font-size: 14px;
  font-weight: bold;
}
.corporate_culture .main .row {
  margin-top: 20px;
}
.corporate_culture .main .box {
  background-color: #fff;
}
.corporate_culture .main .col-1 .box {
  padding: 0 15px 15px 0;
}
.corporate_culture .main .box .tile {
  padding: 15px 0 0 15px;
  text-align: left;
}
.corporate_culture .main h2 {
  color: #03002e;
  font-size: 22px;
}
.corporate_culture .main small {
  color: #999999;
  font-size: 14px;
}
.corporate_culture .main .txt {
  color: #050505;
  font-size: 14px;
  line-height: 2;
  padding: 15px 30px;
  text-align: left;
}
.corporate_culture .main .logo {
  padding-top: 10px;
}

.partners {
  background-color: #fff;
}
.partners .caption {
  position: relative;
}
.partners .caption .content {
  background-color: #4899eb;
  color: #fff;
  bottom: 0;
  height: 360px;
  position: absolute;
}
.partners .caption .cpt {
  font-size: 30px;
}
.partners .caption .desc {
  font-size: 14px;
  padding: 0 15px;
}
.partners .box {
  padding: 10px;
  border: 1px solid #f2f6f9;
}
.partners .pic {
  height: 175px;
}
.partners .pic img {
  margin-top: 35px;
}
.partners .name {
  padding-top: 20px;
  padding-bottom: 10px;
}
