#nav {
    padding: 10px 0;
}
/*百度DIV*/
#nav .baidu {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 34px;
    /*background-image: url("../img/l/21.png");*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-attachment: fixed;*/
    /*background-size: cover;*/
}
#nav .li .img{
    width: 28px;
    height: 28px;
}
#nav .uk-navbar-nav > li > a {
    margin: 0 10px;
}

#nav a {
    color: #300;
    font-size: 18px;
}

#nav .uk-navbar-dropdown-nav a {
    font-size: 16px;
}

#feedback {
    border-bottom: 1px solid #6fabed;
    color: #fff;
    padding-bottom: 80px;
}

#feedback .uk-card {
    background: url(../img/feedback.png) no-repeat 50% 0;
    border-radius: 25px;
    height: 738px;
    padding: 100px 80px;
    margin-right: 200px;
}

#feedback .uk-card-title {
    font-size: 42px;
}

#feedback small {
    font-size: 14px;
    display: inline-block;
    margin-top: 30px;
}

#feedback form {
    margin-top: 70px;
    font-size: 14px;
}

#feedback .uk-form-label {
    color: #fff;
    font-size: 22px;
    margin-bottom: 10px;
}

#feedback .uk-input, #feedback .uk-textarea {
    font-size: 14px;
    border-radius: 10px;
    margin-bottom: 25px;
}

#feedback .uk-textarea {
    height: 178px;
    padding: 20px;
}

#feedback button {
    background-color: #2dc7ed;
    border-radius: 10px;
    font-size: 22px;
    height: 80px;
    padding: 0 40px;
}

#feedback button .uk-icon {
    transition: all .25s;
}

#feedback button:hover .uk-icon {
    transform: translateX(10px);
}

#feedback .card {
    background: url(../img/card.png) no-repeat;
    border-radius: 25px;
    height: 585px;
    position: absolute;
    right: 40px;
    top: 75px;
    width: 515px;
}

#feedback .card .logo {
    margin-top: 50px;
}

#feedback .card .desc {
    color: #fff;
    margin: 50px 0 0 50px;
}

#feedback .card .desc h3 {
    color: #fff;
    margin: 25px 0;
}

#feedback .card .desc p {
    margin: 10px 0;
}

#footer {
    background-color: #4899eb;
    color: #fafafa;
    padding-bottom: 100px;
}

#footer a {
    color: #fafafa;
}

#footer .cpt {
    font-size: 22px;
    margin-bottom: 20px;
}

#footer .cpt small {
    font-family: sans-serif;
    letter-spacing: 0.5px;
    font-size: 16px;
    margin-left: 5px;
}

#footer .content li {
    padding: 25px 0 0 0;
    margin-top: 0;
}

#footer .copyright {
    border-top: 1px solid #dadada;
    height: 74px;
}

#footer .copyright p {
    margin-top: 40px;
    font-size: 14px;
}

.page_loading {
    background-color: rgba(255, 255, 255);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}

.page_loading .anim {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
}

.bg_wrapper {
    background: url(../img/bg1.png) no-repeat 50% 0;
}

.bg_inner {
    background: url(../img/bg2.png) no-repeat 50% 100%;
}

.bg_wrapper #footer {
    background: none;
}

.ads_wrapper {
    background: url(../img/ad/bg.png) no-repeat 50% 0;
}

.ads_wrapper .banner {
    background: url(../img/ad/baidu.png) no-repeat 0 210px;
    min-height: 998px;
    position: relative;
}

.ads_wrapper .banner .txt {
    font-size: 32px;
    line-height: 1.5;
    position: absolute;
    right: 0;
    text-align: right;
    top: 210px;
}

.ads_wrapper .banner .txt .txt-big {
    font-size: 46px;

}